import React from 'react'
import { FacebookIcon, TwitterIcon, PinterestIcon } from './Icons';

const handleOpen = (e) => {
    if (typeof window === 'undefined') {
        return;
    }
    e.preventDefault();
    window.open(e.currentTarget.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
};

const ShareNav = ({ link, title, img }) => (
    <nav className="share-nav">
    <ul className="share-nav__items">
      <li className="share-nav__item">
        <a onClick={handleOpen} href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(link)}&t=${title}`} className="share-nav__link">
          <span className="share-nav__text hide">Facebook</span>
          <div className="share-nav__icon share-nav__icon--facebook">
            <FacebookIcon />
          </div>
        </a>
      </li>
      <li className="share-nav__item">
        <a onClick={handleOpen} href={`https://twitter.com/share?url=${encodeURI(link)}&via=ewallercarlino&text=${title}`} className="share-nav__link">
          <span className="share-nav__text hide">Twitter</span>
          <div className="share-nav__icon share-nav__icon--twitter">
              <TwitterIcon />
          </div>
        </a>
      </li>
      <li className="share-nav__item">
        <a onClick={handleOpen} href={`https://pinterest.com/pin/create/button/?url=${encodeURI(link)}&media=${img}`} className="share-nav__link">
          <span className="share-nav__text hide">Pinterest</span>
          <div className="share-nav__icon share-nav__icon--pinterest">
              <PinterestIcon />
          </div>
        </a>
      </li>
    </ul>
  </nav>
);

export default ShareNav;
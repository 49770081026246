import React from 'react';

const BlogHero = ({ category, img }) => (
    <div className="blog-hero" style={{backgroundImage: `url(${img})`}}>
        <ul className="blog-page__tags tags">
        <li className="tag">
            <span className="tag__link">
            <span className="tag__text">{category}</span>
            </span>
        </li>
        </ul>
    </div>
);

export default BlogHero;
import React, { useContext, useCallback } from 'react';
import { format } from 'date-fns';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import CommentContext from './CommentContext';

const Comment = ({ comment }) => {
    const { replyingToID, setReplyTo } = useContext(CommentContext);
    const toggle = useCallback(
        (e) => {
            e.preventDefault();
            setReplyTo(replyingToID === comment.commentId ? 0 : comment.commentId);
        },
        [comment.commentId, replyingToID]
    );
    let formattedDate;
    try {
        const dateStr = comment.date.split(' ').join('T');
        formattedDate = format(new Date(dateStr), 'MMMM d, yyyy');
    } catch (e) {
        console.error(comment.date, e);
        formattedDate = '';
    }
    

    return (
        <li className="comment">
            <div className="comment-wrap">
                <div className="comment-heading">
                    <div className="comment-author">
                        {comment.author.name}
                    </div>
                    <div className="comment-date">
                        {formattedDate}
                    </div>
                    <div><button className="btn reply-button" type="button" onClick={toggle}>reply →</button></div>
                </div>
                <div className="comment-content" dangerouslySetInnerHTML={{ __html: comment.content }} />
            </div>
            {replyingToID === comment.commentId && (
                <CommentForm comment={comment} />
            )}
            {comment.children && comment.children.nodes.length > 0 && (
                <CommentList>{comment.children.nodes}</CommentList>
            )}
            <style jsx>{`
                :global(.comment-content p) {
                    margin: 0;
                }
                :global(.comments .comment ul) {
                    margin-left: 8rem;
                }
                .comment {
                    margin: 4rem 0;
                }
                .comment-wrap {
                    background: #f4f4f4;
                    padding: 2rem 4rem;
                }
                .comment-heading {
                    display: flex;
                    align-items: center;
                    width: 100%;
                }
                .comment-date {
                    flex-grow: 1;
                    margin-left: 1rem;
                    color: #aaa;
                }
                .comment-author {
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 1.75rem;
                }
                .comment-content {
                    margin: 1rem 0;
                }

                .reply-button {
                    text-transform: uppercase;
                    font-size: 1.5rem;
                    color: #fa00e5;
                }

            `}</style>
        </li>
    );
};

export default Comment;
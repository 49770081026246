import React from 'react';
import Comment from './Comment';

const CommentList = ({ children }) => {
    return (
        <ul className="comments">
            {children.map(c => <Comment comment={c} key={c.commentId} />)}
            <style jsx>{`
                .comments {
                    margin: 2rem 0; 
                }
            `}</style>

        </ul>
    )
};

export default CommentList;
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import CommentForm from './CommentForm';
import CommentList from './CommentList';
import CommentContext from './CommentContext';

// Create a GraphQL query for the comment list.
const commentQuery = gql`
    query($postId: ID!) {
        comments(where: { contentId: $postId, contentStatus: PUBLISH }) {
            nodes {
                ...CommentFields
                children {
                    nodes {
                        ...CommentFields
                        children {
                            nodes {
                                ...CommentFields
                            }
                        }
                    }
                }
            }
        }
    }

    fragment CommentFields on Comment {
        commentId
        content
        date
        commentedOn {
            ...on Post {
                id
            }
        }
        author {
            ...on CommentAuthor {
                name
                email
            }
            ...on User {
                name:firstName
                email
            }
        }
    }

`;

const BlogComments = ({ postId }) => {
    const [ replyingToID, setReplyTo ] = useState(0);

    return (
    <Query query={commentQuery} variables={{ postId }}>
        {({ data, loading, error }) => {
            // Loading and error messages.
            if (loading) return 'Loading comments...';
            if (error) return 'Error loading comments...';
            if (!data) return 'Error loading comments';
            
            const comments = data.comments.nodes;
            return (
                <CommentContext.Provider value={{ replyingToID, setReplyTo, postID: postId }}>
                    <h3>Comments</h3>
                    {comments.length === 0 && (
                        <p>Be the first to leave a comment!</p>
                    )}
                    {comments.length > 0 && (
                        <CommentList>{comments}</CommentList>
                    )}
                    {replyingToID === 0 && (
                        <CommentForm post={postId} />
                    )}
                </CommentContext.Provider>
            );
        }}
    </Query>
    )
};

export default BlogComments;

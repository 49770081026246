import React, { useState, useContext, useCallback } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import CommentContext from './CommentContext';
import { useForm } from 'react-hook-form';

// Create a GraphQL mutation for comment submissions.
const commentSubmitQuery = gql`
	mutation($author: String, $postId: Int, $content: String, $authorEmail: String, $parentId: ID) {
		createComment(
			input: {
				clientMutationId: "CreateComment"
				author: $author
                postId: $postId
                parent: $parentId
				content: $content
				authorEmail: $authorEmail
			}
		) {
			comment {
                id
                approved
            }
		}
	}
`;

const CommentForm = ({ comment }) => {
    const [ commentStatus, setCommentStatus ] = useState(false);
    const { replyingToID, setReplyTo, postID } = useContext(CommentContext);
    const { register, handleSubmit, errors, reset } = useForm();
    const cancel = useCallback((e) => {
        e.preventDefault();
        setReplyTo(0);
    });

    const renderMessage = () => {
        switch (commentStatus) {
            case 'error':
                return <div className="message error">There was a problem submitting your comment. Please try again later.</div>
            case 'success':
                return <div className="success">Thank you for your comment! It has been submitted for approval.</div>
            case 'loading':
                return <div>Submitting comment...</div>;
            default:
                return <div />
        }
    }

    return (
        // Wrap it in our mutation.
        <Mutation   
            mutation={commentSubmitQuery}
            // Set completion state.
            onCompleted={() => {
                setCommentStatus('success');
                reset();
            }}
            // Set error state.
            onError={() => {
                setCommentStatus('error');
            }}
        >
            {(addComment) => (
            <div className="form">
                {comment && <h5>Reply to {comment.author.name}</h5>}
                {!comment && <h3>Leave a comment</h3>}
                {renderMessage()}      
                <form
                onSubmit={handleSubmit(({ content, author, email }) => {
                    // Set initial loading state on submission.
                    setCommentStatus('loading');
                    // Run the mutation.
                    addComment({
                        variables: {
                            author,
                            postId: postID,
                            content,
                            authorEmail: email,
                            parentId: comment.commentId,
                        },
                    });
                })}
                >
                    <fieldset className="fields">
                        <div className="field textarea">
                            <label className="left" htmlFor="content">Comment</label>
                            <textarea rows={8} name="content" ref={register({ required: true })} />
                            {errors.content && <p className="error-message">Please enter a comment</p>}
                        </div>
                        
                        <div className="field text">
                            <label className="left" htmlFor="author">Your name</label>
                            <input required name="author" ref={register({ required: true })} />
                            {errors.author && <p className="error-message">Please enter your name</p>}
                        </div>
                        
                        <div className="field text">
                            <label className="left" htmlFor="email">Your email address</label>            
                            <input required name="email" ref={register({ required: true })} />
                            {errors.email && <p className="error-message">Please provide your email address</p>}
                        </div>

                    </fieldset>
                    <fieldset>
                        <button className="action" name="submit" type="submit">Post comment</button>
                        {' '}
                        {replyingToID !== 0 &&
                            <button className="btn btn--offset" name="cancel" type="button" onClick={cancel}>Cancel</button>
                        }
                    </fieldset>
                </form>
                <style jsx>{`
                .form {
                    margin: 1rem 0;
                }
                .form h5 {
                    margin: 1rem 0;
                }                
                `}</style>

            </div>            
        )}
        </Mutation>
    );
};

export default CommentForm;
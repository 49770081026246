import React from 'react';
import { Link } from 'gatsby';

const BlogBreadcrumbs = ({ title }) => (
    <header className="page-header">
    <div className="page-header__wrapper">
      <nav className="breadcrumbs">
        <ul className="breadcrumbs__items">
          <li className="breadcrumbs__item">
            <Link to="/blog/" className="breadcrumbs__link">Blog</Link>
          </li>
          <li className="breadcrumbs__item breadcrumbs__item--active" dangerouslySetInnerHTML={{__html: title}} />
        </ul>
      </nav>
    </div>
  </header>
);

export default BlogBreadcrumbs;
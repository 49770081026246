/* eslint-disable react/jsx-indent */
import React from 'react';
import { graphql } from 'gatsby';
import BlogSidebar from '../components/BlogSidebar';
import Layout from '../Page';
import SEO from '../components/SEO';
import BlogComments from '../components/BlogComments';
import ShareNav from '../components/ShareNav';
import BlogHeader from '../components/BlogHeader';
import BlogHero from '../components/BlogHero';
import BlogBreadcrumbs from '../components/BlogBreadcrumbs';

const BlogPage = ({ data: { post } }) => {
    let imgSrc = post.backgroundSrc;
    if (!post.backgroundSrc) {
      imgSrc = post.large && post.large.localFile ? post.large.localFile.childImageSharp.fluid.src : null;
    }

    return (
        <Layout>
          <SEO title={post.title} description={post.fields.summary} />
          <BlogBreadcrumbs title={post.title} />
          <div className="blog-page__block">
            <div className="blog-page__wrapper">
              <div className="page-cols">
                <div className="page-col">
                    {post.large && (
                      <BlogHero img={imgSrc} category={post.fields.category} />
                    )}
                  <div className="blog-page__main">
                    <div className="blog-page__share-nav">
                      <ShareNav link={post.link} title={post.title} img={imgSrc} />
                    </div>
                    <div className="blog-page__content">
                      <BlogHeader title={post.title} date={post.date} />
                      <div className="template-content" dangerouslySetInnerHTML={{ __html: post.content }} />
                      {!post.isPreview && (
                        <BlogComments postId={post.wordpress_id} />
                      )}
                    </div>
                  </div>
                </div>
                <BlogSidebar />
              </div>
            </div>
          </div>
        </Layout>
    );
};

export default BlogPage;

export const pageQuery = graphql`
query PostByID($id: String!) {
    post:wordpressPost(id: { eq: $id }) {
        title
        content
        wordpress_id
        fields {
          summary
          category
          link
        }
        large:featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth:1600, maxHeight:900) {
                src
              }
            }
          }
        }
      }    
}
`;

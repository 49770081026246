import React from 'react';

const BlogHeader = ({ title, date }) => (
    <header className="blog-page__blog-header blog-header">
    <h2 className="blog-header__title" dangerouslySetInnerHTML={{ __html: title }} />
    <ul className="meta">
      <li className="meta__item">
        {date}
      </li>
    </ul>
  </header>
);

export default BlogHeader;
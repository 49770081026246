/* eslint-disable react/jsx-indent */
import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Sidebar from './Sidebar';

const BlogSidebar = () => {
	const result = useStaticQuery(graphql`
		{
			allWordpressCategory {
				nodes {
					wordpress_id
					slug
					fields {
						link						
					}
					name
				}
			}
		}
	`);
	const categories = result.allWordpressCategory.nodes;
    return (
		<Sidebar title="Categories">
		    <ul className="sidebar__tags tags tags--subtle">
			{categories.map(cat => {
				return (
					<li className="tag" key={cat.wordpress_id}>
						<Link activeClassName="tag__link--current" to={cat.fields.link} className="tag__link">
							<span className="tag__text">{cat.name}</span>
						</Link>
					</li>	
				)
			})}
			</ul>
		</Sidebar>
    );
};

export default BlogSidebar;
